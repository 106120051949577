/********* PORTFOLIO CARDS *********/
.newAniPortDiv{
    display:flex;
    flex-direction: column;
    width:100%;
  }

  .divWithTitle{
    display:flex;
    flex-direction:row;
    align-items: center;
    justify-content: center;
  }
  .cardsTitleDiv{
    background: var(--whiteCol);
    border-radius: 20px;
    box-shadow: inset 0 0 1px var(--darkCol), 0 0 2px var(--darkCol), 
    0 0 4px var(--lightCol) inset, 0 0 8px var(--darkCol), 
    0 0 16px var(--lightCol)inset,
    0 0 1px hsl(0deg 0% 0% / 0.075), inset 0 0 2px hsl(0deg 0% 0% / 0.075),
    0 0 4px hsl(0deg 0% 0% / 0.075), inset 0 0 8px hsl(0deg 0% 0% / 0.075),
   inset 0 0 16px hsl(0deg 0% 0% / 0.075);
    display:flex;
    flex-direction:column;
    align-items: center;
    justify-content: center;
    width: fit-content;
    height: 8vmin;
    padding: 1vh 1vw 1vh 1vw;
    position: sticky;
    top: 200px;
    margin-top: 50%;
  }
  .titleContainer{
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .cardsUnitTitle{
    font-family: 'Geologica', sans-serif;
    font-variation-settings: 'wght' 550;
    font-variation-settings: 'CRSV' 0.5;
    font-variation-settings:  'SHRP' 100;
    color: var(--contrastCol);
    font-size: 6vmin;
    text-shadow: 4px 2px 4px var(--darkCol);
    text-align:center;
  }  
  .newMainDiv{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: 'Poppins', sans-serif;
  }

  /********* INSIDE THE MAP FUNCTION **********/
  .newIndividualDiv{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 5vw;
    background: var(--whiteCol);
    border-radius: 20px;
    box-shadow:  0 0 1px var(--darkCol) , 0 0 2px var(--darkCol) inset, 
    0 0 4px var(--lightCol) , 0 0 8px var(--darkCol) inset, 
    0 0 16px var(--lightCol),
    0 0 1px hsl(0deg 0% 0% / 0.075) inset,  0 0 2px hsl(0deg 0% 0% / 0.075),
    0 0 4px hsl(0deg 0% 0% / 0.075) inset,  0 0 8px hsl(0deg 0% 0% / 0.075),
    0 0 16px hsl(0deg 0% 0% / 0.075);
    width: 350px;
    height: 500px;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 0px 20px 20px 20px;
    overflow: visible;
  }
 
  .card-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    padding-top: 20px;
    margin:0 8vw 4vh 8vw;  
    width: 112%;
    height:100%;
    overflow:visible;
  }

  .splash {
    position: absolute;
    overflow: visible;
    bottom: 0;
    left: 1;
    right: 1;
    top: 0;
    margin-top: 55px;
    width: 100%;
    height:75vh;
    background: linear-gradient(195deg, var(--whiteCol), var(--lightCol));
    box-shadow: inset 0 0 1px var(--darkCol), 0 0 2px var(--darkCol) inset, 
    0 0 4px var(--lightCol) inset, 
    0 0 16px var(--lightCol)inset, inset 0 0 2px hsl(0deg 0% 0% / 0.075), inset 0 0 8px hsl(0deg 0% 0% / 0.075),
   inset 0 0 16px hsl(0deg 0% 0% / 0.075);
    clip-path: path(
      "M 0 303.5 C 0 292.454 8.995 285.101 20 283.5 L 460 219.5 C 470.085 218.033 480 228.454 480 239.5 L 500 430 C 500 441.046 491.046 450 480 450 L 20 450 C 8.954 450 0 441.046 0 430 Z"
    );
  }
    
   .card {
      font-size: 2vmax;
      font-family: 'Poppins', sans-serif;
      width: 75%;
      min-height: 600px;
      height: 80vh;
      overflow-y: scroll;
      padding: 8px;
      display: flex;
      flex-direction:column;
      align-items: center;
      justify-content: center;
      margin-top: 5vh;
      margin-bottom: 5vh;
      margin-left: 5vw;
      background: white;
      border-radius: 20px;
      box-shadow: inset 0 0 1px var(--blackCol), 0 0 2px var(--infoCol), 
      0 0 4px var(--blackCol) , 0 0 8px var(--infoCol), 
      0 0 16px var(--blackCol),
      0 0 1px hsl(0deg 0% 0% / 0.075) inset, 0 0 2px hsl(0deg 0% 0% / 0.075) inset,
      0 0 4px hsl(0deg 0% 0% / 0.075) inset, 0 0 8px hsl(0deg 0% 0% / 0.075) inset, 0 0 16px hsl(0deg 0% 0% / 0.075) inset;
      transform-origin: 10% 60%;
    }
    .card h1{
      font-size: 3vmax;
      font-family: 'League Spartan', sans-serf;
      text-align: center;
      background-image: linear-gradient(45deg, red, magenta, aqua, greenyellow);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-stroke-color: transparent;
      -webkit-text-stroke-width: 3px;
      -webkit-text-fill-color: var(--darkCol);
    }
    .card img{
      width:18vmin;
      height: auto;
      border-radius: 20px;
      margin-bottom: 15px;
    }
    .card h2{
      font-family: 'League Spartan', sans-serif;
      font-size: 1.5vmax;
      margin-bottom: -5px;
    }
    .card a{
      font-size: 1.4vmax;
      text-decoration: none;
      color: var(--lightCol);
    }
    .card a:hover{
      font-size: 1.45vmax;
      color: var(--contrastCol)
    }
    .card h3{
      font-family: 'League Spartan', sans-serif;  
      font-size: 1.5vmax;
      margin-bottom: -20px;
    }
    .card li{
      font-size: 1.2vmax;
    }
    .mainJS{
      width:100%
  }
    .jsDiv, .htmlDiv, .reactDiv, .pythonDiv{
      display:flex;
      flex-direction: row;
      justify-content: space-between;
      width:100%
  }
  .indexCardsDiv{
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
/*   
  @media screen and (max-width: 970px){
    .newIndividualDiv{
      width: fit-content;
    }
  } */
  /* 
@media screen and (max-width: 1100px){
  .appNavBar{ max-height: 200px}
}

@media screen and (max-width: 720px){
  .appNavBar{ max-height: 150px}
}*/

@media screen and (max-width: 450px){
  .newMainDiv{
    flex-direction: row;
    overflow-x: visible;
    overflow-y: hidden;
  }
  .divWithTitle{
    overflow-x: scroll;
  }
  .newIndividualDiv{
    width: 250px;
  }
  .card-container{
    height: fit-content;
    width: 250px;
  }
  .splash{
    width: 290px;
  }
  .card{
    width: 205px;
    height: 360px;
    overflow-y: scroll;
  }
  .newAniPortDiv{
    width: 100%;
  }
  .titleContainer{
    height:fit-content;
  }
} 