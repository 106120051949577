/********* NAVBAR *********/
.navbar{
  width: 100%;
  max-width: 1200px;
  height: 150px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--lightCol);
  overflow-y:visible
}
.navFirst{
  position:fixed;
  top:0;
  width: 100%;
  padding-top:2vh;
  z-index: 999999;
  max-width: 1200px;
  height: 115px; 
  display: grid;
  grid-template-columns: repeat(11, 1fr);
  grid-template-rows: repeat(3, 35px), 150px;
  background-color: var(--lightCol);
}
.customDivider svg{
  fill: var(--lightCol);
  overflow: visible;
}
.navSVG{
  position: absolute;
  top: 120px;
  left:0;
  fill: blue;
  z-index: 99999;
}
.navSVG, .footSVG{
  width: 100%;
}
.shape-fill{
  fill:var(--lightCol);
  width:100%;
}
.navGap{
    grid-column: 1/12;
    grid-row: 1/2;
}
.navHome{
  grid-column: 2/3;
  grid-row: 2/4;
}
.navAbout{
  grid-column: 4/5;
  grid-row: 2/4;
}
.navLogo{
  grid-column: 6/7;
  grid-row: 1/4;
}
.navbarLogo{
  width: 10vmax;
  height: 10vmax;
  max-width:116px;
  max-height: 116px;
  margin-top: -8px;
}
.navbarLogo:hover{
  scale: 1.1
}
.navCV{
  grid-column: 8/9;
  grid-row: 2/4;
}
.navPortfolio{
  grid-column: 10/11;
  grid-row: 2/4;
}
.navOptions{
    grid-column: 9/12;
    grid-row: 3/4;
}
.navOptions2{
    display:grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1;
    margin-top: 4vh;
}
.navDeveloper{
    grid-column: 1/2;
}
.navHMUA{
    grid-column: 3/4;
}
.page{
  font-size: 2.5em;
  font-family: 'League Spartan', sans-serif;
  font-weight: 800;
  text-decoration: none;
  color: var(--infoCol)
}
.current{
  font-size: 3em;
  font-family: 'League Spartan', sans-serif;
  font-weight: 800;
  text-decoration: none;
  color: var(--contrastCol);
}
.page:hover{
  color: var(--contrastCol);  
  font-size: 3em;  
}
  
/********* FOOTER *********/
.footSVG {
  display: flex;
  position:relative;
  width: 100%;
  max-width:1200px;
  fill: var(--lightCol);
}
.footer{
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
}
.footerImg{
  height: 120px;
} 
.footerText{
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 2vh;
  font-size: 1.8vmin;
  font-family: 'Raleway', sans-serif;
  font-weight:600;
  line-height: 0.5px;
  width: 80%;
  white-space: nowrap;
  text-align:center;
}
.footerP{
  margin-bottom: 10px;
}

.github{
    text-decoration: none;
    color: var(--darkCol);
    font-size: 1.9vmin;
    font-family: 'Raleway', sans-serif;
    font-weight:600;
    line-height: 2px;
}
.github:hover{
    text-decoration: none;  
    color: var(--lightCol);
}
.privAndTerms{
  display: flex;
  justify-content: space-between;
  width: 20vw;
  margin-top: 25px;
}
.footPrivacy{
  text-align: center;
  text-decoration: none;
    color: var(--darkCol);
    font-size: 1.9vmin;
    font-family: 'Raleway', sans-serif;
    font-weight:600;
    line-height: 2px;
}
.footPrivacy:hover{
  text-decoration: none;  
  color: var(--lightCol);
}
.footTerms{
  text-align: center;
  text-decoration: none;
  color: var(--darkCol);
  font-size: 1.9vmin;
  font-family: 'Raleway', sans-serif;
  font-weight:600;
  line-height: 2px;
}
.footTerms:hover{
  text-decoration: none;  
  color: var(--lightCol);
}

@media screen and (max-width: 480px){
  .page{ margin-left:15px; margin-top:16.5px; font-size: 3.3vw; }
  .current{ margin:10px; margin-top: 15px; font-size: 4vw; }
  .navLeft{ margin-left:-30px; margin-right: -10px;}
  .navRight{ margin-left:40px; margin-right: -30px;}
  .navColours{ height:50px}
  .navbar{width: 100vw}
  .navFirst{width: 100vw}
}