:root{
    --bgGrad: linear-gradient(45deg, var(--lightCol) 20%, var(--whiteCol) 67.5%, var(--lightCol) 90%);
    --bgHoverGrad: linear-gradient(135deg, var(--darkCol) 20%, var(--lightCol) 67.5%, var(--darkCol) 90%);
    
}
@property --bg-angle {
    inherits: false;
    initial-value: 0deg;
    syntax: "<angle>";
  }
  @property --bg-reverse-angle {
    inherits: false;
    initial-value: 360deg;
    syntax: "<angle>";
  }

.movingGradient{ 
    position: sticky;
    margin: 10px;
    text-align: center;
    text-justify: center;
    width: fit-content;
    border-radius: 18px;
    padding: 2px;
    padding-left: 5px;
    padding-right: 5px;
    animation: rainbowAnimation 2.5s infinite linear running;
    background:
        var(--bgGrad)
      padding-box,
    conic-gradient(
        from var(--bg-angle) in oklch longer hue,
        oklch(1 0.37 0) 0 0 
      )
      border-box; 
    border: 6.5px solid transparent;
    color: transparent;
    box-shadow: -5px 3px 6px var(--darkCol), 4px -1px 4px var(--darkCol);
    &:hover {
        border: 3.5px solid transparent;
        font-weight: 650;
        box-shadow:inset;
        background:
        conic-gradient(
        from var(--bg-angle) in oklch longer hue,
        oklch(1 0.37 0) 0 0 
      )  
      padding-box,
      var(--bgHoverGrad)
      border-box; 
      animation-play-state: paused;
    }
}

#button{
  top: 430px
}

.movingGradientText{
    text-decoration: none;
    font-family: 'Geologica';
    font-weight: 700;
    font-size: 1.5em;
    animation: rainbowReverseAnimation 15s infinite linear running;
    background:
    conic-gradient(
        from var(--bg-reverse-angle) in oklch longer hue,
        oklch(1 0.37 0 / 0.5) 0 0
      )
      border-box;
    color: transparent;
    background-clip: text;
    
}
.movingGradient:hover .movingGradientText{
  animation-play-state: paused;
        background:
        var(--bgHoverGrad) text;
        padding-top: 25px
}


.gradientText{
    color: transparent;
    background: linear-gradient(135deg, var(--lightCol) 20%, var(--blackCol) 67.5%, var(--focalCol) 90%);
    background-clip: text;
}

@media screen and (max-width: 800px){
    .firstLandingImg{
      width: 400px;
      top: 120px;
    }
    .left{
        margin-left: 30px;
        width: 100vw;
        white-space: +30px;
        white-space-collapse: preserve-breaks;
    }
    .headlineText{
        top: 240px;
    }
    .subHeadingText{
        top: 350px
    }
    .subHeadingSecond{
        top: 450px
    }
  }
  
  @keyframes rainbowAnimation{
    to {
        --bg-angle:360deg;
    }
  }

  @keyframes rainbowReverseAnimation{
    to {
        --bg-reverse-angle:0deg;
    }
  }