/********* FONTS *********/
@import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@100..900');
@import url('https://fonts.googleapis.com/css2?family=League+Gothic:wdth@75..100');
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Geologica:wght,CRSV,SHRP@100..900,1,100&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@font-face {
  font-family: 'NJTD-Regular';
  src: url(./fonts/NJTD-Regular.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
};
@font-face {
  font-family: 'NJTD-Bold';
  src: url(./fonts/NJTD-Bold.ttf) format('truetype');
  font-weight: bold;
  font-style:normal
}
/********* COLOUR VARIABLES *********/

:root{
  --whiteCol: hsl(228, 99%, 99%);
  --lightCol: #EEEEEE;
  --focalCol: #4D4D4D;
  --darkCol: #323232;
  --blackCol: hsl(228, 99%, 5%);
  --contrastCol: rgb(2.472187886279357, 25.95797280593325, 85.90852904820765);
  --infoCol: #3c3c3c;
  --borderCol: #000000;
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  width:100vw;
  max-width: 1200px;
  background-color: var(--whiteCol);
}
#navBar{
  width:100vw;
  max-width: 1200px;
  height: 160px;
  background-color: var(--lightCol);
}
.noNav{
  display:none;
}
.appFooter{
  width: 100%;
}
.appOutlet{
  background-color: var(--whiteCol);
  display:flex;
  position: relative;
  top: 0;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1200px;
}
.appLogo{
  position:fixed;
  z-index: 9999;
}
/* 
@media screen and (max-width: 1100px){
  .appNavBar{ max-height: 200px}
}

@media screen and (max-width: 720px){
  .appNavBar{ max-height: 150px}
}

@media screen and (max-width: 450px){
  .appNavBar{ max-height: 100px}
} */

@media screen and (max-width: 480px){
  .appOutlet{
    width: 100vw;
    overflow-x: hidden;
  }
}