
.logoContainer{
    width: 10vw;
    max-width: 135px;
    position: relative;
}
.logoSVG{
    /* width: 100px;
    height: 100px; */
    position: absolute;
    z-index: 999999;
    top:0;
    left:0;
}
/* #memoji{
    height:100px;
    width: 100px;
} */
