/********* HOMEPAGE GITHUB REPOS *********/
.outerRepoDiv{
    display:flex;
    width:100%;
    flex-direction:column;
    justify-content: center;
    align-items: center;
    text-align:center;
    background: none;
  }
  .outerRepoDiv h1{
    color: var(--focalCol);
    font-family: 'Geologica', sans-serif;
    font-size: 4vmin;
    font-weight: 600;
    font-variation-settings:  'SHRP' 100; 
    text-shadow: 2px 1px 3px var(--darkCol);
  }
  .individualRepo{
    display: flex;
    flex-direction: column;
    width: 36vw;
    max-width: 450px;
    height: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 0px 20px 20px 20px;
    background-color: var(--lightCol);
    border-radius: 10px;
    box-shadow: 3px 1px 4px var(--darkCol), -3px -1px 4px var(--darkCol), -1px 3px 4px var(--darkCol), 1px -3px 4px var(--darkCol);
  }
  .individualRepo p{
    margin-bottom: -10px;
  }
  .mainReposDiv{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Poppins', sans-serif;
    font-size: 1.4vmin;
    color: var(--infoCol);
    width: 80vw;
    align-self: center;
    background: none;
    position:relative;
  }
  .details{
    text-align: left;
  }
  .repoTitle{
    font-family: 'Geologica', sans-serif;
    margin-top: 0.5vh;
    font-weight: 600;
    font-size: 3.2vmin;
    width: 100%;
    height: fit-content;
    overflow:visible;
    background-image: linear-gradient(75deg, red, magenta, aqua, greenyellow);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-stroke-color: transparent;
    -webkit-text-stroke-width: 0.4vmin;
    -webkit-text-fill-color: var(--darkCol);
    margin-left: auto;
    margin-right:auto;
  }
  .repoLink{
    text-decoration: none;
    color: var(--darkCol);
    font-weight:450;
    white-space: nowrap;
  }
  .repoLink:hover{
    color: var(--whiteCol);
    font-weight:550;
  }
  #git0, #git2, #git4{
    text-align: right;
  }
  #divgit0, #divgit2, #divgit4{
    margin-left: 25vw;
  }
  #divgit1, #divgit3{
    margin-right: 25vw;
  }

  @media screen and (max-width: 970px){
    .individualRepo{
      width: fit-content;
    }
  }