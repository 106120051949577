
/********* ABOUT PAGE *********/
.aboutPage{
    font-family: 'Poppins', sans-serif;
    font-weight:400;
    font-size: 2.5vh;
    background-color: var(--whiteCol);
    color: var(--infoCol);
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: repeat(8, 150px );
    gap: 2px;
    
}
.title{
    font-size: 4vw;
    font-family: 'Geologica', sans-serif;
    font-variation-settings: 'wght' 550;
    font-variation-settings: 'CRSV' 0.5;
    font-variation-settings:  'SHRP' 100;
    color: var(--focalCol);
    text-shadow: 3px 1px 4px var(--darkCol);
    grid-column: 4/6;
    grid-row: 1/2; 
}
.aboutTitle{
    font-size: 4vmax;
    font-family: 'Geologica', sans-serif;
    font-variation-settings: 'wght' 550;
    font-variation-settings: 'CRSV' 0.5;
    font-variation-settings:  'SHRP' 100;
    color: var(--focalCol);
    text-shadow: 3px 1px 4px var(--darkCol);
    grid-column-start: 3;
    grid-column-end: 6;
    grid-row-start: 1;
    grid-row-end: 2;
    line-height:1px;
    text-align:center;
}

/* Image */
.first{
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 5;
    max-width:180px;
    height:auto;
}
/* Hi I'm Nathan */
.second{
    grid-column-start: 3;
    grid-column-end: 5;
    grid-row-start: 2;
    grid-row-end: 3;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}
/* Hate to be... */
.third{
    grid-column-start: 3;
    grid-column-end: 6;
    grid-row-start: 3;
    grid-row-end: 4;
    text-align: center;
}
/* Try as I */
.fourth{
    grid-column-start: 3;
    grid-column-end: 7;
    grid-row-start: 4;
    grid-row-end: 6;  
    opacity: 0;
    animation: fadeIn 4s;
    animation-delay: 5s;
    animation-fill-mode: forwards;
}
/* Thanks */
.fifth{
    grid-column-start: 5;
    grid-column-end: 6;
    grid-row-start: 5;
    grid-row-end: 6; 
    opacity: 0;
    animation: fadeGrow 8s;
    animation-delay: 9s;
    animation-fill-mode: forwards;
}
/* NJTD */
.sixth{
    grid-column-start: 6;
    grid-column-end: 7;
    grid-row-start: 3;
    grid-row-end: 5;  
}
/* More info */
.seventh{
    grid-column-start: 2;
    grid-column-end: 4;
    grid-row-start: 5;
    grid-row-end: 7;
    animation: fadeIn 4s;
    animation-delay: 9s;
    animation-fill-mode: forwards;
}

/***********PORTFOLIO PAGE **********/
.portfolioPage{
    background-color: var(--whiteCol);
    width: 100%;
    height:100%;
    margin-bottom: 20vh;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}
.portfolioTitle{
    margin-top: 30vh;
    font-size: 6vw;
    font-family: 'Geologica', sans-serif;
    font-variation-settings: 'wght' 550;
    font-variation-settings: 'CRSV' 0.5;
    font-variation-settings:  'SHRP' 100;
    color: var(--focalCol);
    text-shadow: 3px 1px 4px var(--darkCol);
    text-align:center;
}
.portfolioOptions{
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.portCardsDiv{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}
.jsDiv{
    display:flex;
    flex-direction: row;
    width: 100% 
}

  /*************** HMUA ****************/
  .hmuaPage{
    height: fit-content;
    background-color: var(--whiteCol);
    font-family: 'Raleway', sans-serif;
    text-align: center;
  }
  .hmuaTitle{
    font-size: 4vw;
    font-family: 'Geologica', sans-serif;
    font-variation-settings: 'wght' 550;
    font-variation-settings: 'CRSV' 0.5;
    font-variation-settings:  'SHRP' 100;
    color: var(--focalCol);
    text-shadow: 3px 1px 4px var(--darkCol);
    margin-top: 50px;
}
 
  .hmuaContent, .hmuaImages{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
.hmuaScrollContainer {
    background-color: var(--darkCol);
    overflow: auto;
    white-space: nowrap;
    padding-left: 0.2vw;
    padding-right: 0.2vw;
    width:85vw;
    border: 0.6vw solid var(--darkCol);
    box-shadow: 3px 1px 4px var(--lightCol), -3px -1px 4px var(--lightCol),1px -3px 4px var(--lightCol), -1px 3px 4px var(--lightCol);
}
.slide{
    max-width: 100%;
    min-height: 100%;
    padding: 0.5vh;
    margin: 1vw;
    max-height: 65vh;
    box-shadow: 3px 1px 4px var(--blackCol) inset, -3px -1px 4px var(--blackCol) inset,1px -3px 4px var(--blackCol) inset, -1px 3px 4px var(--blackCol) inset;
}
.vogue{
    margin-bottom: 8vh;        
}
.hmuaText{
    margin-top: 30px;
    margin-bottom: 60px;
}
  /*************** PRIVACY ****************/
 .privacy{
    height: 100%;
    color: var(--blackCol); 
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 2vh;
 }
 .privSection{
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
 }
 .privTitle{
    font-size: 3vw;
    font-family: 'Geologica', sans-serif;
    font-variation-settings: 'wght' 550;
    font-variation-settings: 'CRSV' 0.5;
    font-variation-settings:  'SHRP' 100;
    color: var(--darkCol);
    text-shadow: 3px 2px 4px var(--blackCol);
    text-align:center;
 }
 .privacyHeading{
    display:flex;
    font-size: 1.2vw;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    color: var(--focalCol); 
    margin-bottom: -10px;
 }
 .leftRight{
    display: flex;
    margin-top: 5vh;
 }
 .privContact{
    margin-right: 10vw;
 }
 .privacyContactHeading{
    display:flex;
    font-size: 2vmin;
    text-align: left;
    font-family: 'Geologica', sans-serif;
    color: var(--focalCol); 
    margin-bottom: -1vh;
    align-self: flex-start;
 }
 .privacyContactDetails{
    text-align: left;
    font-family: 'Raleway', sans-serif;
    font-size: 1vmax;
    font-weight: 400;
    color: var(--blackCol); 
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
 }
 .priv{
    line-height:2px;
    margin-bottom: -2px;
    font-weight: 500;
 }
 .privacyLink{
    text-align: left;
    font-family: 'Poppins', sans-serif;
    font-size: 1vmax;
    font-weight: 600;
    color: var(--lightCol); 
    display: inline-flex;
    text-decoration: none;
 }
 .privacyLink:hover{
    font-weight: 800;
    color: var(--contrastCol);
 }
.privacyDate{
    display:flex;
    align-self: center;
    font-size: 1vw;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    color: var(--infoCol); 
    justify-self: flex-end;
    margin-left: 20vw;
    margin-top: 5vh;
 }


  /*************** TERMS ****************/
 .terms{
    height: 100%;
    font-family: 'Raleway', sans-serif;
    font-size: 1.2vmax;
    font-weight: 400;
    color: var(--blackCol); 
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 80%;
 }
 .terms p{
    margin-bottom: -0.5vh;
 }
 .termTitle{
    font-size: 3vmax;
    font-family: 'Geologica', sans-serif;
    font-variation-settings: 'wght' 550;
    font-variation-settings: 'CRSV' 0.5;
    font-variation-settings:  'SHRP' 100;
    color: var(--darkCol);
    text-shadow: 3px 2px 4px var(--blackCol);
    text-align:center;
 }
 .terms li{
    list-style-position: inside;
    text-align: left;
    margin-bottom: 5px;
    font-size: 1.1vmax;
 }
 .termsHeading{
    font-weight: 600;
    margin-bottom: -1.5vh;
 }


  /*************** KEYFRAMES ****************/
@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}
@keyframes fadeGrow {
    0% {opacity:0; font-size: 1.5vw; font-weight:400}
    50% {opacity:1}
    100% {opacity:1; font-size: 3.5vw; font-weight:700}
}

@media screen and (max-width: 1000px){
  
  }
  @media screen and (max-width: 480px){
    .portfolioPage{ width: 100vw; overflow-x: hidden}
  }
  @media screen and (min-width: 481px) and (max-width: 768px){
    
  }
  @media screen and (min-width: 769px){
    
  }

