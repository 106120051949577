/********* COLOUR PICKER *********/
.colourPicker{
    display: flex;
    width:100%;
    max-width: 1200px;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: safe;
    margin: 20px;
    z-index: 9999;
    height: auto;
    background-color: var(--lightCol);
  }
  .cPList{
    display:none;
  }
  .colourList{
    display: hidden;
    flex-direction: column;
  }
  .anyColourDiv, .customSet{
    background:none;
  }
  .cloudBtn{
    background: none;
    border: none;
  }
  .pickerBtn{
    display:flex;
    background-image: url('../images/miniPicker.png');
    background-size: contain;
    border-style:solid;
    border-color: var(--borderCol);
    border-width:3px;
    border-radius:5%;
    width:56px;
    height:56px;
  }
  .listModal{
    display:flex;
    flex-direction:column;
    align-items: center;
    background-color: var(--darkCol);
    margin-top:60px;
  }
  .anyColModal{
    /* position:fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--lightCol);
    width: 100vw;
    height: 100vh;
  }
  .closeCols{
    font-family: 'League Spartan', sans-serif;
    font-weight: 900;
    font-size: 40px;
    color: var(--darkCol);
    text-shadow: 2px 2px 4px var(--whiteCol), -2px -2px 4px var(--whiteCol), -2px 2px 4px var(--whiteCol), 2px -2px 4px var(--whiteCol);
  }
  .closeCols:hover{
    color: var(--whiteCol);
    text-shadow: 2px 2px 4px var(--darkCol), -2px -2px 4px var(--darkCol), -2px 2px 4px var(--darkCol), 2px -2px 4px var(--darkCol);
  }
  .pickerList{
    margin:20px;
    width:56px;
  }
  #colourPicker{
    z-index: 9999;
  }
  .miniColPicker, .listBtn, .darkLight{
    border-style:solid;
    border-color: var(--borderCol);
    border-width:3px;
    border-radius: 5%;
    margin-bottom:10px;
  }
  
  .darkLight{
    display: flex;
    justify-content: center;
    align-items: center;
    width:50px;
  }
  .dark{
    background-color: var(--darkCol);
    width:25px;
    height: 50px;
  }
  .light{
    background-color: var(--whiteCol);
    width:25px;
    height: 50px;
  }
  .darkLight:hover .dark{
    background-color: var(--whiteCol);
  }
  .darkLight:hover .light{
    background-color: var(--darkCol);
  }
  .defaultBox{
    height: 50px;
    width:50px;
    display:flex;
    flex-direction: column;
    border-style:solid;
    border-color: var(--borderCol);
    border-width:3px;
    border-radius: 5px;
    margin-left: 2vmin;
  }
  .default1{
    background-color: hsl(0, 0%, 98%);
    height: 50px;
    width: calc(50/6)px;
  }
  .defaultBox:hover .default1{
    background-color: hsl(0, 0%, 8%);
  }
  .default2{
    background-color: rgb(165, 165, 165);
    height: 50px;
    width: calc(50/6)px;
  }
  .defaultBox:hover .default2{
    background-color: rgb(63, 63, 63);
  }
  .default3{
    background-color: rgb(153, 153, 153);
    height: 50px;
    width: calc(50/6)px;
  }
  .defaultBox:hover .default3{
    background-color: rgb(102, 102, 102);
  }
  .default4{
    background-color: rgb(102, 102, 102);
    height: 50px;
    width: calc(50/6)px;
  }
  .defaultBox:hover .default4{
    background-color: rgb(153, 153, 153);
  }
  .default5{
    background-color: rgb(63, 63, 63);
    height: 50px;
    width: calc(50/6)px;
  }
  .defaultBox:hover .default5{
    background-color: rgb(165, 165, 165);
  }
  .default6{
    background-color: hsl(0, 0%, 8%);
    height: 50px;
    width: calc(50/6)px;
  }
  .defaultBox:hover .default6{
    background-color: hsl(0, 0%, 98%);
  }

  @media screen and (max-width: 750px){
    .col1, .col2, .col3, .col4, .col5, .darkLight{
      width: 4vw;
      height: calc(4vw/5);
    }
    .dark, .light{
      width: 2vw;
    }
    .pickerBtn, .darkLight, .miniColPicker, .listBtn, .darkLight, .dark, .light{
      height: 4vw;
      width:4vw;
    }
  }
  
@media screen and (max-width: 480px){
  .colourPicker{ display: none; }
  .cPList{ display:flex; flex-direction: column; align-items: center; justify-content: space-evenly; justify-self:center; }
  .listModal{ margin-top: 110px}
}
@media screen and (min-width: 481px) and (max-width: 768px){
  .colourPicker{ display: flex; }
  .cPList{ display:none; }
}
@media screen and (min-width: 769px){
  .colourPicker{ display: flex; }
  .cPList{ display:none; }
}