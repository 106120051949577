@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base{
  :root{
    --whiteCol: hsl(228deg 99% 99%);
    --lightCol: #EEEEEE;
    --focalCol: #4D4D4D;
    --darkCol: #323232;
    --blackCol: hsl(228, 99%, 5%);
    --contrastCol: rgb(2.472187886279357, 25.95797280593325, 85.90852904820765);
    --infoCol: #3c3c3c;
    --borderCol: #000000;
  }
}
html{
  background-color: var(--infoCol);
}
body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display:flex;
  flex-direction: column;
  align-items: center;
  padding-left: auto;
  padding-right:auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@media screen and (max-width: 480px){
  html{
    width: 100vw;
    overflow-x: hidden;
  }
}